import httpCommon from '~/api/httpCommon/httpCommon.js';

export default {
    /**
     * Asynchronously fetches home data based on the specified language.
     * Sends a GET request to the '/home-data' endpoint using the configured Axios instance from httpCommon.
     * Extracts and returns the 'body' part of the response data.
     * @param {string} lang - The language code to specify the language for the request.
     * @returns {Promise<any>} A promise that resolves to the 'body' part of the home data.
     */
    get(lang) {
        return httpCommon()
            .get('/home-data', { params: { locale: lang } })
            .then(res => res.data.body);
    },
};
