<template>
    <NuxtLayout>
        <div class="home-view">
            <default-header-section :calculate-form="contentPage.calculateForm" :banners="contentPage.banners" />
            <info-section :content-pages="contentPage.textInfoBlock" />
            <map-section :content-pages="contentPage.mapInfoBlock" @click-scroll-button="scrollToContactForm" />
            <default-advantages-section :content-pages="contentPage.listInfoBlock" />
            <FaqSection :data="contentPage.faq" />
            <DescriptionSection :data="contentPage.about" />
        </div>
    </NuxtLayout>
</template>

<script setup>
import { useContentPages } from '@/stores/homeStores';

import InfoSection from '~/pages/index/sections/InfoSection.vue';
import MapSection from '~/pages/index/sections/MapSection.vue';
import DefaultAdvantagesSection from '~~/pages/index/sections/DefaultAdvantagesSection.vue';
import DefaultHeaderSection from '~~/pages/index//sections/DefaultHeaderSection.vue';
import homeApi from '~/api/index/index.js';
import FaqSection from '~/pages/index/sections/FaqSection.vue';
import DescriptionSection from '~/pages/index/sections/DescriptionSection.vue';
import { useLayout } from '~/stores/layoutStore.ts';

const runtimeConfig = useRuntimeConfig();
const { locale } = useI18n();
useHead({
    script: [
        {
            src: runtimeConfig.public.API_MAPS_YANDEX_GET,
        },
    ],
});

const { data: contentPage } = await useAsyncData('data', () => homeApi.get(locale.value), {
    watch: [locale],
});
const contentPagesStore = useContentPages();
const layoutPageStore = useLayout();
const contactForm = computed(() => contentPage.value.contactForm);
const phoneCallback = layoutPageStore.getCommunicationPhone();
watch(
    contentPage,
    () => {
        contentPagesStore.setContentConsultForm(contentPage.value.contactForm);
        contentPagesStore.setConsultationTranslate(contactForm.value.buttons_text.content, phoneCallback, contactForm.value.content[0].text);
    },
    {
        immediate: true,
    }
);

const scrollToContactForm = () => {
    document.getElementById('contact-form').scrollIntoView();
};
</script>

<style>
</style>
